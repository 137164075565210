import React from 'react'
// import { QueryClient, QueryClientProvider } from 'react-query'
import Dashboard from './dashboard'

const App = () => {
  // const queryClient = new QueryClient()
  return (
    // <QueryClientProvider client={queryClient}>
      <Dashboard />
    // </QueryClientProvider>
  )
}

export default App